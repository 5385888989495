import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const ImportTestCase = () => {
  return (
    <LayoutComponent>
      <h1>Import Test Cases</h1>

      <p>Using our import wizard, you can import test cases—including all of your test case data—into GAINSS plugin in the following ways:</p>

      <ul>
        <li>From Comma-Separated-Values (CSV) files</li>
        <li>From Microsoft Excel files (via CSV)</li>
      </ul>

      <p>The import wizard is easy to use and helps you map and import all of your test-case data as quickly as possible.</p>

      <p>If your data is in the Numbers spreadsheet program on a Mac OS, no worries! Just export to CSV before you proceed with the import process.</p>

      <h2>Considerations for Excel XLS and XLSX Files</h2>

      <p>If your test data is in an Excel XLS or XLSX file, you need to export the file to CSV so that you can import it into GAINSS. Excel creates one CSV file per sheet. Keep in mind that some Excel features are not transferred to CSV. For example, formulas are converted to text and are not calculated.</p>

      <h2>Importing Test Cases From CSV</h2>

      <h3>Before You Import: Format Your CSV File</h3>

      <p>To import and allow you to map your fields and data accurately, the CSV file you use to import your test-case information needs to abide by a few formatting specifications.</p>

      {/* <h3>Define Your Test Steps</h3>

      <p>There are two ways you can format your test steps. Choose one.</p>

      <ul>
        <li><strong>Option 1:</strong> You can import the script type (Steps) by creating a column for Step and another column for the Expected Result.</li>
        <li><strong>Option 2:</strong> You can import the script type (Plain Text) by creating a column for Test Script Plain Text.</li>
      </ul> */}

      <h3>System Fields Available for Import</h3>

      <p>This table lists all of the system fields that are importable via the CSV.</p>

      <h3>Importing Your Data to GAINSS</h3>

      <ol>
        <li>Open the project you want to import the test cases into, then click <strong>Import</strong>.</li>
        <li>Click <strong>Excel CSV</strong>. The wizard progresses to the Setup stage.</li>
        <li>Upload your CSV file by dragging and dropping it into the defined area or by clicking <strong>Choose the File</strong> and selecting it from your local storage. Your file uploads to the wizard.</li>
        <li>Complete the fields in the Settings section:
          <ul>
            {/* <li><strong>Destination Folder:</strong> The folder into which you want to import your test cases.</li> */}
            <li><strong>Date Format:</strong> The format your test cases use, including the separator (slash, dash, or dot).</li>
            <li><strong>File Encoding:</strong> The file encoding used on your CSV file. Choosing the incorrect encoding will affect your field mapping.</li>
            <li><strong>CSV Delimiter:</strong> The delimiter used on your CSV file. Choosing the incorrect delimiter will affect your field mapping.</li>
            <li><strong>Start Import at Row:</strong> The row in which you want the import to begin.</li>
            <li>The <strong>This row contains the field names</strong> checkbox is selected by default. Deselect this option if the row specified does not contain field names.</li>
          </ul>
        </li>
        <li>Click <strong>Next</strong>. The wizard progresses to the Field Mapping stage.</li>
        <li>Map your fields, then click <strong>Next</strong>. Unmapped fields will not be created.</li>
        <li>The wizard progresses to the Data Mapping stage.</li>
        <li>Map your data type for components, priorities, statuses, labels, etc. A message appears if there is no data to map. Note the option to automatically create unmapped labels.</li>
        <li>Click <strong>Import</strong>. Your test-case information imports to GAINSS. The wizard advances to the Results stage, where import messages and errors appear.</li>
      </ol>
    </LayoutComponent>
  )
}

export default ImportTestCase
